<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('project_page.cancel_requests_title')"
      :sub-title="$t('project_page.cancel_requests_subititle')"
    />

    <!-- <div>
      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />
    </div> -->
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
              v-if="getAllProjects"
              :per-page="filterData.per_page"
              :items="getAllProjects"
              :fields="fields"
              :status="status"
              :custimized-items="[
                { name: 'price' },
                { name: 'created_at' },
                { name: 'end_date' },
                { name: 'action' },
              ]"
            >
              <template slot="created_at" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">{{
                    formatedDate(data.item.created_at)
                  }}</span>
                </div>
              </template>

              <template slot="price" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3"
                    >{{ data.item.price }} {{ $t('common.sr_currency') }}</span
                  >
                </div>
              </template>
              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                  variant="background"
                  toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                  menu-class="rounded-14"
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1" />
                  </template>
                  <b-dropdown-item
                    link-class="py-0min-height: 52px;"
                    @click="orderDetails(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2" />
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.status != 6"
                    link-class="py-0min-height: 52px;"
                    @click="cancelOrder(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <stopping-check-icon class="mx-2" />
                      {{ $t('project_page.cancel') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </w-tables>
            <wameed-no-data
              v-if="getAllProjects && getAllProjects.length < 1"
              icon="nodata-icon"
              :title="$t('offers_page.no_data')"
              :sub-title="$t('offers_page.no_data_text')"
            />
          </div>
          <warning-modal
            variant="danger"
            variant2="danger"
            icon-name="stopped-modal-icon"
            :visible="showCanceledOrder"
            :title="$t('common.cancel_project_title')"
            :sub-title="$t('common.cancel_project_subtitle')"
            :btn-title="$t('common.cancel_project')"
            @close="showCanceledOrder = false"
            @submitAction="cancelOrderFun()"
          />
        </b-col>

        <b-col
          v-if="getAllProjects && getAllProjects.length > 0"
          lg="12"
          md="12"
          class="px-0"
        >
          <wameed-pagination
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalProjects.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
// import ordersCard from "@/components/pages/orders/OrdersCard.vue";
import WTables from '@/components/pages/applicants/WTables.vue';
import WarningModal from '@/components/WarningModal.vue';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextArea from '@/components/TextArea.vue';
export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    // ordersCard,
    WTables,
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
  },
  data() {
    return {
      showCanceledOrder: false,
      showRejectedOrder: false,
      rejectedDescription: '',
      orderId: null,
      filterData: {
        status: '12',
        industry_preference: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      content: [
        {
          title: 'status',
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          data: [
            { title: 'قائمة', id: 1 },
            { title: 'تم التقديم', id: 2 },
            { title: 'منتهية', id: 9 },
          ],
        },
        {
          title: 'industry_preference',
          id: 'id',
          name: 'name',
          key: 'industry_preference',
          selectedData: [],
          data: [],
        },
      ],
      fields: [
        {
          key: 'hr_job_title',
          label: this.$i18n.t('form_wizard_order.management_title_title'),
          sortable: true,
        },
        {
          key: 'price',
          label: this.$i18n.t('offers_page.price'),
          sortable: true,
        },
        {
          key: 'company_name',
          label: this.$i18n.t('offers_page.employer'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$i18n.t('offers_page.date_application'),
          sortable: true,
        },
        {
          key: 'cancel_reason',
          label: this.$i18n.t('project_page.cancel_reason'),
          sortable: true,
        },
        { key: 'action', label: '', sortable: false },
      ],
      status: [
        {
          0: 'project_rejected',
          1: 'project_active',
          2: 'project_before_paying',
          3: 'project_under_review',
          4: 'project_offer_selection',
          5: 'project_agreed',
          6: 'project_canceled',
          7: 'project_pending',
          8: 'project_finished_employee_not_paid',
          9: 'project_finished',
          10: 'project_close_request',
          11: 'project_sent_by_employee',
          12: 'project_request_cancel',
        },
        {
          0: 'danger',
          1: 'warning',
          2: 'info',
          3: 'icon',
          4: 'success',
          5: 'warning',
          6: 'danger',
          7: 'icon',
          8: 'warning',
          9: 'success',
          10: 'icon',
          11: 'success',
          12: 'warning',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllProjects: 'getAllProjects',
      getTotalProjects: 'getTotalProjects',
    }),
  },
  watch: {},
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadProjects: 'loadProjects',
      changeProjectStatus: 'changeProjectStatus',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadProjects(this.filterData);
    },
    cancelOrder(id) {
      this.showCanceledOrder = true;
      this.orderId = id;
    },
    packageDetails(id) {
      this.$router.push({
        name: 'orders-list-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    applyFilter() {
      this.content.forEach((item) => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        this.filterData = {
          status: '12',
          industry_preference: '',
          page: 1,
          per_page: 10,
          order_by: 'desc',
          search: '',
        };
        // this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    cancelOrderFun() {
      this.changeProjectStatus({
        id: this.orderId,
        status: 6,
      }).then(() => {
        this.loadData();
        this.showCanceledOrder = false;
      });
    },
    orderDetails(id) {
      this.$router.push({
        name: 'project-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
  },
};
</script>
